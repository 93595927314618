import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  leftContainer: {
    background: theme?.palette?.header?.main,
    width: '35%',
    height: '100%',
  },
  onboardingPageWrapper: {
    boxSizing: 'border-box',
    height: 0,
    width: '100%',
    flex: 1,
    padding: '30px',
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '50px',
    '@media only screen and (min-width:875px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  testVideoWrapper: {
    width: 'max-content',
    paddingTop: '15px',
    '@media only screen and (min-width:875px)': {
      paddingTop: 0,
    },
  },
  cameraOnIcon: {
    height: '24px',
    width: '24px',
  },
  testVideo: {
    maxWidth: '130px',
    color: theme?.palette?.secondaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.secondaryButton?.border}`,
    padding: '5px 12px',
    fontWeight: 600,
  },
  rightContainer: {
    width: '65%',
    height: '100%',
    background: theme?.palette?.darkBackground?.main,
  },
  quizDetailsWrapper: {
    boxSizing: 'border-box',
    height: 0,
    width: '100%',
    flex: 1,
    padding: '40px',
  },
  quizDetailsContainer: {
    boxSizing: 'border-box',
    background: 'white',
    height: '100%',
    width: '100%',
    borderRadius: '6px',
    padding: '25px 30px',
  },
  quizDetails: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  instructionsAndDescriptionWrapper: {
    width: '100%',
    height: 'max-content',
  },
  titleAndDetailsWrapper: {
    boxSizing: 'border-box',
    padding: '0px 10px 0px 20px',
    overflowY: 'auto',
    width: '100%',
    flex: 1,
    height: 0,
    '@media only screen and (min-width:1025px)': {
      padding: '0px 20px 0px 30px',
    },
    '@media only screen and (min-width:1100px)': {
      padding: '0px 40px 0px 50px',
    },
  },
  quizInfoDetailsWrapper: {
    width: '100%',
    height: 'max-content',
  },
  questionDetailsWrapper: {
    paddingTop: '20px',
  },
  title: {
    fontSize: '24px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '125%',
    paddingBottom: '50px',
    '@media only screen and (min-width:1025px)': {
      fontSize: '26px',
    },
    '@media only screen and (min-width:1100px)': {
      fontSize: '30px',
    },
  },

  durationIcon: {
    width: '26px',
    height: '26px',
    marginRight: '10px',
    '@media only screen and (min-width:1025px)': {
      width: '28px',
      height: '28px',
    },
    '@media only screen and (min-width:1100px)': {
      width: '30px',
      height: '30px',
    },
  },
  durationText: {
    fontSize: '18px',
    color: theme?.palette?.greenTextColor?.main,
    flex: 1,
    borderBottom: `solid 1px ${theme?.palette?.onboardingPageDividerLines?.main}`,
    paddingBottom: '20px',
    '@media only screen and (min-width:1025px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:1100px)': {
      fontSize: '22px',
    },
  },
  questionsIcon: {
    width: '26px',
    height: '26px',
    marginRight: '10px',
    '@media only screen and (min-width:1025px)': {
      width: '28px',
      height: '28px',
    },
    '@media only screen and (min-width:1100px)': {
      width: '30px',
      height: '30px',
    },
  },
  questionsText: {
    fontSize: '18px',
    color: theme?.palette?.greenTextColor?.main,
    paddingBottom: '10px',
    '@media only screen and (min-width:1025px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:1100px)': {
      fontSize: '22px',
    },
  },
  multipleChoiceQuestionsText: {
    fontSize: '12px',
    color: theme?.palette?.onboardingPageGrayTextColor?.main,
    '@media only screen and (min-width:1025px)': {
      fontSize: '14px',
    },
    paddingBottom: (props) => (props?.codingQuestions ? '6px' : '0px'),
  },
  questionsCount: {
    fontWeight: 700,
  },
  codingQuestionsText: {
    fontSize: '12px',
    color: theme?.palette?.onboardingPageGrayTextColor?.main,
    '@media only screen and (min-width:1025px)': {
      fontSize: '14px',
    },
  },
  divider: {
    borderTop: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    width: '100%',
    height: 0,
    paddingBottom: '20px',
  },
  copyrightWrapper: {
    width: '100%',
    height: '90px',
    borderTop: `solid 1px ${theme?.palette?.onboardingPageDividerLines?.main}`,
    color: theme?.palette?.onboardingPageGrayTextColor?.main,
  },

  descriptionHeading: {
    fontSize: '18px',
    fontWeight: 700,
    wordBreak: 'break-word',
    paddingBottom: '20px',
    color: '#202020',
    '@media only screen and (min-width:1025px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:1100px)': {
      fontSize: '22px',
    },
  },

  description: {
    fontSize: '14px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    paddingBottom: '20px',
    color: '#202020',
  },

  instructionsHeading: {
    fontSize: '18px',
    fontWeight: 700,
    wordBreak: 'break-word',
    paddingBottom: '15px',
    color: '#202020',
    '@media only screen and (min-width:1025px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:1100px)': {
      fontSize: '22px',
    },
  },
  quizInstructions: {
    fontSize: '14px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    paddingBottom: '40px',
    color: '#202020',
  },
  ctaWrapper: {
    width: '100%',
    minHeight: (props) =>
      !props?.isQuizStarted || props?.isQuizEnded ? '50px' : '90px',
    height: 'max-content',
    background: 'white',
    borderTop: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    padding: (props) =>
      !props?.isQuizStarted || props?.isQuizEnded
        ? '0px 40px 0px 50px'
        : '20px 40px 20px 50px',
  },
}))
export { useStyles }
