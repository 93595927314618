/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Paper } from '@material-ui/core'
import Login from './Login'

import {
  getSecondsRemainingFromDateTimeToNow,
  secondsToTime,
  isDateTimeInThePast,
  convertISOToLocalDateTime,
  decodeJwt,
} from '../../utils'
import { QuizTimeLeft } from '../QuizPage/QuizPage'
import { useStyles } from './QuizOnboardingPageCTAStyles'

function QuizOnboardingPageCTA({
  quizInfoDetails,
  username,
  setUsername,
  loginMutation,
  handlePublicQuizStart,
  isQuizStarted,
  setIsQuizStarted,
  isQuizEnded,
}) {
  const [timer, setTimer] = useState('0')
  const timeLeftRef = React.useRef(
    new QuizTimeLeft((reduceSeconds) => {
      setTimer((sec) => {
        return sec - reduceSeconds
      })
    }, 1000)
  )
  const { quizId } = useParams()
  const classes = useStyles()

  const secondsInADay = 86400

  useEffect(() => {
    setTimer(() => {
      return getSecondsRemainingFromDateTimeToNow(quizInfoDetails?.time?.start)
    })
  }, [])

  useEffect(() => {
    if (timer > 0 && !timeLeftRef.current.timerStarted) {
      timeLeftRef.current.start()
    } else if (timer <= 0 && timeLeftRef.current.timerStarted) {
      setIsQuizStarted(() => {
        return isDateTimeInThePast(quizInfoDetails?.time?.start)
      })
    }
  }, [timer])
  const history = useHistory()
  const handleResponse = (response) => {
    const loggedInUserInfo = decodeJwt(response?.accessToken)
    if (loggedInUserInfo) {
      Cookies.set(`${loggedInUserInfo?.sub}_token`, response?.accessToken)
      Cookies.set('refresh_token', response?.refreshToken)
      sessionStorage.setItem('isLoggedIn', 'true')
      sessionStorage.setItem('userID', loggedInUserInfo?.sub)
      sessionStorage.setItem('userID_token', response?.accessToken)
    }
    return history.push(`/quiz/${quizId}/questions`)
  }

  const returnContent = () => {
    if (!isQuizStarted) {
      return (
        <Grid
          container
          className={classes.countDownTimerContainer}
          justify="center"
          alignItems="center"
        >
          {timer < secondsInADay ? (
            <Typography className={classes.countDownTimer}>
              Quiz will start in
              <span className={classes.time}> {secondsToTime(timer)}</span>
            </Typography>
          ) : (
            <Typography className={classes.countDownTimer}>
              Quiz will start on{' '}
              <span className={classes.time}>
                {convertISOToLocalDateTime(quizInfoDetails?.time?.start)}
              </span>
            </Typography>
          )}
        </Grid>
      )
    }
    if (isQuizEnded) {
      return (
        <Grid
          container
          className={classes.countDownTimerContainer}
          justify="center"
          alignItems="center"
        >
          <Typography className={classes.quizEnded}>Quiz has ended</Typography>
        </Grid>
      )
    }
    return (
      <Grid container justify="flex-end" className={classes.startTestContainer}>
        {quizInfoDetails?.quizConfig?.type?.toLowerCase() === 'private' ? (
          <Login
            quizInfoDetails={quizInfoDetails}
            handleResponse={handleResponse}
          />
        ) : (
          <Grid className={classes.publicQuizOnboardingPage} container>
            <Grid item className={classes.inputName}>
              <Grid className={classes.nameTextFieldWrapper}>
                <Paper elevation={0} className={classes.nameTextField}>
                  <InputBase
                    placeholder="Enter your name"
                    variant="outlined"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    fullWidth
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        handlePublicQuizStart()
                      }
                    }}
                    inputProps={{
                      className: classes.nameTextField,
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>

            <Button
              className={classes.continueButton}
              onClick={handlePublicQuizStart}
            >
              {loginMutation?.isLoading ? (
                <CircularProgress
                  size="1.5rem"
                  className={classes.userNameLoader}
                />
              ) : (
                <>Start Test</>
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }
  return (
    <Grid container direction="column" className={classes.quizOnBoardingPage}>
      {returnContent()}
    </Grid>
  )
}

export default QuizOnboardingPageCTA
